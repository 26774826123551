import {
  Box,
  Button,
  Card,
  Image,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Notification,
  Page,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";
import Track from "./divbg.png";
import { useTranslation } from "react-i18next";
import i18n from './i18n';
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "19 Divider Styles",
  "10 Creative Decoration Styles",
  "Divider Without Watermark",
  "On Time Customer Support",
];

const INTERVAL = 4000;

function App() {
  const { t } =useTranslation();
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const [index, setIndex] = React.useState<number>(0);
const initialBannerState: boolean = !isUpgraded;
const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] = React.useState<boolean>(initialBannerState);
  React.useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);


  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);
  const BASE_URL = `https://certifiedcode.wixsite.com/dividers/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setInstanceData(data);
          const isFree = data.instance?.isFree === false;
          setIsUpgraded(isFree);
          setIsUpgradeBannerOpen(!isFree);

          if (
            !data.instance.permissions.includes("WIX_DEVELOPERS.GET_SITE_OWNER")
          ) {
            window.open(
              `https://go.certifiedcode.global/get/a3bc0e33-ca2d-4017-a5dd-1b7111e722be/${data.site.siteId}`,
              "_blank"
            );
          }
        }
      })
      .catch(() => {});
  }

  const planId = (instanceData as any)?.instance?.availablePlans[0]?.name;

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=a3bc0e33-ca2d-4017-a5dd-1b7111e722be&redirectUrl=https://certifiedcode.wixsite.com/dividers/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeRedirectionUrl = `https://www.wix.com/apps/upgrade/${"a3bc0e33-ca2d-4017-a5dd-1b7111e722be"}?appInstanceId=${
    (instanceData as any)["instance"]["instanceId"]
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <>
              <Notification
                theme="premium"
                show={isUpgradeBannerOpen}
                type={"sticky"}
              >
                <Notification.TextLabel>
                  {t('upgradeMessage', { content: t(`content.${index}`) })}
                </Notification.TextLabel>
                <Notification.ActionButton
                  onClick={() => {
                    window.open(upgradeRedirectionUrl, "_blank");
                  }}
                >
                  {t('upgrade-button')}
                </Notification.ActionButton>
                <Notification.CloseButton
                  onClick={() => setIsUpgradeBannerOpen(false)}
                />
              </Notification>
              <Box height="840px" verticalAlign="middle">
                <MarketingPageLayoutContent
                  title={t('divider-title')}
                  content={t('divider-content')}
                  actions={
                    <Box direction="vertical" gap="SP2">
                      <Button
                        skin="premium"
                        prefixIcon={<Icons.PremiumFilled />}
                        as="a"
                        target="_blank"
                        href={upgradeRedirectionUrl}
                      >
                        {isUpgraded ?t('manage-sub-button') : t('upgrade-app-button')}
                      </Button>
                      {/* <Button
                      prefixIcon={<Icons.GetStarted />}
                      as="a"
                      href="https://support.certifiedcode.us/en/articles/8678985-track-setup-guide"
                      target="_blank"
                    >
                      Setup Guide
                    </Button> */}
                      <Button
                        skin="inverted"
                        as="a"
                        href="https://bridget.reviews.certifiedcode.us/a3bc0e33-ca2d-4017-a5dd-1b7111e722be"
                        target="_blank"
                      >
                        {t('five-star-button')}
                      </Button>
                    </Box>
                  }
                />
              </Box>
            </>
          }
          image={<Image transparent borderRadius={0} src={Track} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
